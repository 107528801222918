import gql from "graphql-tag";

export const assignPerson = gql`
  mutation assignPerson($id: Int!, $personId: Int!) {
    assignPerson(id: $id, personId: $personId) {
      id
      person {
        id
        needsAccount
      }
    }
  }
`;

export const deleteActivity = gql`
  mutation deleteActivity($id: Int!) {
    deleteActivity(id: $id) {
      id
    }
  }
`;

export const smsNotification = gql`
  mutation smsNotification($input: SMSInput!) {
    smsNotification(input: $input) {
      id
      description
    }
  }
`;

export const assignCurrentUserToPersonActivity = gql`
  mutation assignCurrentUserToPersonActivity($id: Int!) {
    assignCurrentUserToPersonActivity(id: $id) {
      id
    }
  }
`;
